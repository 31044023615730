import React, { useState, useEffect } from 'react'
import { Avatar } from 'baseui/avatar'
import { Display4 } from 'baseui/typography'
import { useStyletron } from 'baseui'
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalButton,
} from 'baseui/modal'
import { Button, KIND, SHAPE, SIZE } from 'baseui/button'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { Notification } from 'baseui/notification'
import Emoji from './Emoji'
import { FiExternalLink } from 'react-icons/fi'
import whatsAppLogo from '../images/WhatsApp.svg'
import { StyledLink } from 'baseui/link'
// import { StatefulPopover, TRIGGER_TYPE, PLACEMENT } from 'baseui/popover'
// import { FiInstagram } from 'react-icons/fi'

const SuccessModal = ({ waLink, isOpen, setIsOpen, qrDataUrl }) => {
  const [css] = useStyletron()
  const [copied, setCopied] = useState(false)
  // const [dmCopied, setDMCopied] = useState(false)
  const [showNotification, setShowNotification] = useState(false)
  const [downloadString, setDownloadString] = useState(qrDataUrl)

  const goToPremium = () => {
    window.plausible('Modal To App')
    window.open('https://app.wa.link/register', '_blank')
  }

  const checkCopiedAndClose = () => {
    if (copied) {
      setCopied(false)
      setIsOpen(false)
      setShowNotification(false)
    } else {
      setShowNotification(true)
    }
  }

  useEffect(() => {
    setDownloadString(qrDataUrl)
  }, [waLink, qrDataUrl])

  return (
    <Modal
      isOpen={isOpen}
      onClose={checkCopiedAndClose}
      unstable_ModalBackdropScroll={true}
      overrides={{
        Root: {
          style: {
            zIndex: '1000',
          },
        },
        Dialog: {
          style: {
            borderTopRightRadius: '10px',
            borderTopLeftRadius: '10px',
            borderBottomRightRadius: '10px',
            borderBottomLeftRadius: '10px',
          },
        },
      }}
    >
      <ModalHeader>Este é seu link encurtado para WhatsApp</ModalHeader>
      <ModalBody>
        <p>
          Copie e compartilhe seu link onde quiser ser contatado facilmente.
          {/* (Use{' '}
          <StatefulPopover
            showArrow
            content={
              <>
                <Paragraph2 padding="scale300">
                  Atualmente o{' '}
                  <FiInstagram style={{ verticalAlign: 'middle' }} /> Instagram
                  apresenta erro ao enviar mensagens com links que redirecionam
                  para qualquer plataforma Meta. Use esta versão do seu link se
                  for enviá-lo em mensagens DM do Instagram.
                  <br />
                  <CopyToClipboard
                    text={'https://' + waLink.replace('https://', 'dm.')}
                    onCopy={() => setDMCopied(true)}
                  >
                    <a href="/" onClick={e => e.preventDefault()}>
                      {dmCopied ? 'Copiado!' : 'Copiar link para Instagram DM'}
                    </a>
                  </CopyToClipboard>
                </Paragraph2>
              </>
            }
            accessibilityType={'tooltip'}
            triggerType={TRIGGER_TYPE.hover}
            placement={PLACEMENT.bottom}
            overrides={{
              Body: {
                style: {
                  width: '300px',
                  marginTop: '10px',
                  borderTopLeftRadius: '15px',
                  borderBottomLeftRadius: '15px',
                  borderTopRightRadius: '15px',
                  borderBottomRightRadius: '15px',
                },
              },
              Inner: {
                style: {
                  borderTopLeftRadius: '15px',
                  borderBottomLeftRadius: '15px',
                  borderTopRightRadius: '15px',
                  borderBottomRightRadius: '15px',
                },
              },
            }}
          >
            <strong style={{ textDecoration: 'underline' }}>
              {waLink.replace('https://', 'dm.')}
            </strong>
          </StatefulPopover>{' '}
          para enviar o link por mensagens do Instagram) */}
        </p>
        <div
          className={css({
            display: 'flex',
            justifyContent: 'center',
            flexWrap: 'wrap',
          })}
        >
          <Avatar name="WA logo" size="scale1200" src={whatsAppLogo} />
          <a
            href={waLink}
            target="_blank"
            rel="noopener noreferrer"
            className={css({
              marginLeft: '5px',
              wordWrap: 'break-word',
              textDecoration: 'none',
            })}
          >
            <Display4>{waLink.replace('https://', '')}</Display4>
          </a>
        </div>
        <div
          className={css({
            display: 'flex',
            justifyContent: 'space-evenly',
            marginTop: '10px',
            marginBottom: '10px',
            cursor: 'pointer',
          })}
          onClick={goToPremium}
        >
          <div
            className={css({
              color: 'rgb(54, 135, 89)',
              // color: '#fff',
              backgroundColor: ' rgb(240, 249, 244)',
              // backgroundColor: '#1fd1f9',
              // backgroundImage:
              //   'linear-gradient(315deg, #1fd1f9 0%, #b621fe 74%)',
              // 'linear-gradient(-45deg, #23a6d5, #23d5ab)',
              width: '100%',
              borderRadius: '10px',
              padding: '1rem',
              boxShadow: 'rgba(0, 0, 0, 0.2) 0px 1px 4px',
              // boxShadow: 'rgba(0,0,0, 0.8) 0px 1px 5px',
              border: '1px solid rgb(54, 135, 89)',
              textAlign: 'center',
              transition: '0.2s',
              ':hover': {
                // boxShadow: 'rgba(0,0,0, 0.8) 0px 1px 5px',
                // boxShadow: 'rgba(0,0,0, 0.8) 0px 2px 5px',
                boxShadow: 'rgba(0,0,0, 0.4) 0px 2px 5px',
              },
            })}
          >
            <p style={{ fontSize: '15px' }}>
              Obtenha um a <Emoji symbol={'👑'} />{' '}
              <a
                href="https://app.wa.link/register"
                target="_blank"
                rel="noopener noreferrer"
                alt="Plan premium"
                onClick={e => e.preventDefault()}
                // style={{ color: '#fff' }}
              >
                Plano Premium
              </a>{' '}
              partir de <strong>USD $6/ano</strong> para criar um(s) wa.link(s)
              com:
              {/* Get your own <strong> wa.link/YourBrand </strong> with a{' '}
              <Emoji symbol={'👑'} />
              <OutboundLink
                href="https://create.wa.link/premium"
                target="_blank"
                rel="noopener noreferrer"
                alt="Premium plan"
                onClick={e => e.preventDefault()}
                style={{ color: '#fff' }}
              >
                Premium plan
              </OutboundLink>
              <Emoji symbol={'👑'} />, so you can track clicks and edit the link
              info at any time. */}
            </p>
            <ul
              className="checkList"
              // style={{ textAlign: 'left', listStyleType: 'none' }}
            >
              <li>
                Link de marca <strong>(wa.link/SuaMarca)</strong>
              </li>
              <li>
                O telefone, a mensagem personalizada e URL do link{' '}
                <strong>podem ser editados</strong> a qualquer momento.
              </li>
              <li>
                <strong>Quantidade de cliques</strong> por hora, dia ou mês com
                fonte, sistema operacional e país.
              </li>
              <li>
                Acesso à página Multilink, <strong>serviço Multiagente</strong>,
                mecanismo de busca de links e muito mais...
              </li>
            </ul>
            <Button
              shape={SHAPE.pill}
              size={SIZE.compact}
              // endEnhancer={() => <FiExternalLink size={16} />}
              overrides={{
                BaseButton: {
                  style: {
                    marginTop: '1rem',
                    boxShadow: 'rgba(0, 0, 0, 0.3) 0px 1px 4px',
                  },
                },
              }}
            >
              <Emoji symbol={'👑'} /> Obtenha Premium
            </Button>
          </div>
        </div>
        {!copied && showNotification && (
          <div
            className={css({
              display: 'flex',
              justifyContent: 'space-evenly',
              marginTop: '10px',
            })}
          >
            <Notification
              overrides={{
                Body: {
                  style: {
                    borderTopRightRadius: '10px',
                    borderTopLeftRadius: '10px',
                    borderBottomRightRadius: '10px',
                    borderBottomLeftRadius: '10px',
                  },
                },
              }}
            >
              Certifique-se de copiar o link antes de fechar
            </Notification>
          </div>
        )}
        <div
          className={css({
            display: 'flex',
            justifyContent: 'space-evenly',
          })}
        >
          <img
            src={downloadString}
            alt="generated-qr"
            style={{ width: 300, height: 300 }}
          />
        </div>
        <div
          className={css({
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-end',
            justifyContent: 'center',
          })}
        >
          <StyledLink
            target="_blank"
            rel="noopener"
            href={`https://app.ohmy.link/?url=${waLink}`}
          >
            <span style={{ marginRight: '5px', fontSize: '1rem' }}>
              Editar o código QR
            </span>
            <FiExternalLink size={18} />
          </StyledLink>
        </div>
      </ModalBody>
      <ModalFooter>
        <CopyToClipboard text={waLink} onCopy={() => setCopied(true)}>
          <ModalButton
            shape={SHAPE.pill}
            size={SIZE.compact}
            overrides={{
              BaseButton: {
                style: {
                  backgroundColor: '#00b66c',
                  boxShadow: 'rgba(0, 0, 0, 0.3) 0px 1px 4px',
                },
              },
            }}
          >
            {copied ? 'Copiado!' : 'Copiar link'}
          </ModalButton>
        </CopyToClipboard>
        <a
          download={waLink.replace('https://', '') + '.png'}
          href={downloadString}
          style={{ textDecoration: 'none' }}
        >
          <ModalButton
            kind={KIND.secondary}
            shape={SHAPE.pill}
            size={SIZE.compact}
            overrides={{
              BaseButton: {
                style: {
                  boxShadow: 'rgba(0, 0, 0, 0.3) 0px 1px 4px',
                },
              },
            }}
          >
            Baixar QR
          </ModalButton>
        </a>
      </ModalFooter>
    </Modal>
  )
}

export default SuccessModal
